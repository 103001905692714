import React from "react";
import { Link } from "gatsby";
import sklogosticky from "../images/sk-logo-sticky.png";
import fb from "../images/social/fb.png";
import linkedin from "../images/social/in.png";
import mail from "../images/social/mail.png";
import tss from "../images/tss.png";
import qp from "../images/qp.svg";
import "../styles/main.css";
import styles from "./SheetkraftFooter.module.css";
//import CookieConsent from "react-cookie-consent";

const SheetkraftFooter = () => {
  return (
    <div>
      <section
        className="section container sk-footer-blue "
        id="partner"
        data-spy="scroll"
      >
        <div className={`row ${styles.rowMargin}`}>
          <div className="col-sm-12 col-md-12 col-lg-7">
            <div className="row ">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <Link to="/" className={styles.linkWidth}>
                  <img
                    src={sklogosticky}
                    className={`${styles.sklogosticky} sk-logo-footer`}
                    alt="sticky sk logo"
                  />
                </Link>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8">
                <div className="row sk-links">
                  <div className="col-sm-4  col-md-4 col-lg-4">
                    <div className="sk-footer-menu-item">
                      <Link to="/#product"> Product </Link>
                    </div>
                    <div className="sk-footer-menu-item">
                     <a href="https://docs.sheetkraft.com/">Documentation</a>
                    </div>
                    <div className="sk-footer-menu-item">
                      <Link to="/blog/category/case-studies/"> Case Studies </Link>
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <div className="sk-footer-menu-item">
                      <Link to="/#team"> About Team </Link>
                    </div>
                    <div className="sk-footer-menu-item">
                      <Link to="/careers" className={styles.careersLink}>
                        Careers
                      </Link>
                    </div>
                    <div className="sk-footer-menu-item">
                      <Link to="/privacy/">Privacy Policy</Link>
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <div className="sk-footer-menu-item"> Follow Us At: </div>
                    <a
                      href="https://www.facebook.com/quantumphinance/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={fb} className="sk-social-icon" alt="fb-logo" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/quantum-phinance-pvt.-ltd./"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={linkedin}
                        className="sk-social-icon"
                        alt="sk linkedin"
                      />
                    </a>
                    <a href="mailto:contactus@quantumphinance.com">
                      <img
                        src={mail}
                        className="sk-social-icon"
                        alt="sk mail"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`col-sm-12 col-md-12 col-lg-5 sk-partner ${styles.skPartner}`}
          >
            <div className="row">
              <div
                className={`col-sm-5 col-md-5 col-lg-7 ${styles.ourPartner}`}
              >
                <div className="sk-partner-title"> Our Partner </div>
                <div className="sk-partner-about">
                  Target State Solutions team are experts in Financial Risk
                  Management and Process Automation.
                </div>
                <div className="sk-partner-about">
                  TSS have key Strategic Partnerships in place providing
                  bandwidth to enable your business to achieve its Target State.
                </div>
              </div>
              <div
                className={`col-sm-7 col-md-7 col-lg-5 text-center ${styles.padding10}`}
              >
                <a
                  href="https://www.targetstatesolutions.com"
                  target="_blank"
                  className={styles.linkWidth}
                  rel="noopener noreferrer"
                >
                  <img
                    src={tss}
                    className={`${styles.sklogosticky} tssLogo`}
                    alt="tss logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <br />
      </section>
      <footer>
        <div className="container ">
          <div className="row sk-footer-rights">
            <div className="col-sm-6 my-auto sk-left">
              <span>
                Copyright &copy; {new Date().getFullYear()}, All rights reserved
                by
              </span>
              <a
                href="https://www.quantumphinance.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="color-pri-red"> Quantum Phinance</span>
              </a>
            </div>
            <div className="col-sm-6 sk-right">
              <span>A product of &nbsp;</span>
              <a
                href="https://www.quantumphinance.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={`img-responsive ${styles.qp}`}
                  src={qp}
                  alt="Quantum phinance Logo"
                />
              </a>
            </div>
            {/* <div className="col-lg-10 col-lg-offset-2">
              <CookieConsent
                location="bottom"
                buttonText="Accept"                
                cookieName="gatsby-gdpr-google-analytics"    
                style={{zIndex:'999999'}}            
              >
                We use cookies on this site to enhance your user experience.
              </CookieConsent>
            </div> */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SheetkraftFooter;
